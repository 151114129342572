<template>
  <div>
    <div class="card height-auto v-application--is-ltr">
      <div class="card-body">
        <v-form v-model="valid" ref="parentForm">
          <div class="row">
            <div class="col-md-4">
              <h3 class="main-color">
                {{ $t("Account Statement: Create Invoice") }}
              </h3>
            </div>
          </div>

          <v-row class="headerTable-div">
            <v-col md="5" sm="12">
              {{ $t("Partner Name :") }} {{ partner.name }}
            </v-col>
            <v-col md="3" sm="12">
              {{ $t("Partner Code :") }} {{ partner.code }}
            </v-col>
            <v-col md="2" sm="12">
              {{ $t("Partner Type :") }} {{ partner.type }}
            </v-col>
            <v-col md="2" sm="12">
              {{ $t("Grade :") }} {{ partner.grade }}
            </v-col>

            <v-col md="2" sm="12" class="text-right">
              <!-- <v-btn class="confirm success" @click="createPayment()">
                        Create Payment
                    </v-btn> -->
            </v-col>
          </v-row>
          <hr class="second" />

          <v-row>
            <v-col cols="12" md="6">
              <div class="box">
                <h4 class="second-title">{{ $t("Select Payment") }}</h4>
                <ul class="payment-methods text-center">
                  <li class="text-center disabled">
                    <!-- <p class="icon" @click="selectPaymentMethod('Visa')"> -->
                    <p class="icon">
                      <i class="fas fa-money-check"></i>
                    </p>
                    <p class="text">{{ $t("Visa") }}</p>
                  </li>

                  <li class="text-center" :class="submitted ? 'disabled' : ''">
                    <p
                      class="icon selectable"
                      :class="paymentMethod == 'Cash' ? 'active' : ''"
                      @click="selectPaymentMethod('Cash')"
                    >
                      <i class="far fa-money-bill-alt"></i>
                    </p>
                    <p class="text">{{ $t("Cash") }}</p>
                  </li>

                  <li class="text-center" :class="submitted ? 'disabled' : ''">
                    <p
                      class="icon selectable"
                      :class="paymentMethod == 'Bank Transfer' ? 'active' : ''"
                      @click="selectPaymentMethod('Bank Transfer')"
                    >
                      <i class="fas fa-dollar-sign"></i>
                    </p>
                    <p class="text">{{ $t("Bank Transfer") }}</p>
                  </li>

                  <li class="text-center" :class="submitted ? 'disabled' : ''">
                    <p
                      class="icon selectable"
                      :class="paymentMethod == 'Check' ? 'active' : ''"
                      @click="selectPaymentMethod('Check')"
                    >
                      <i class="far fa-credit-card"></i>
                    </p>
                    <p class="text">{{ $t("Check") }}</p>
                  </li>
                </ul>
              </div>
            </v-col>

            <v-col cols="12" md="6">
              <div class="box">
                <v-row>
                  <v-col cols="12" md="6">
                    <p class="key">{{ $t("Transaction Id") }}</p>
                    <p class="value">{{ paymentObject.transaction_id }}</p>
                  </v-col>
                  <v-col cols="10" md="5">
                    <p class="key">{{ $t("Transaction Date") }}</p>
                    <v-text-field
                      id="transaction_date"
                      autocomplete="off"
                      v-model="paymentObject.transaction_date"
                      solo
                      dense
                      :disabled="submitted"
                      @keydown.prevent
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" md="1">
                    <v-icon
                      medium
                      style="float: right; opacity: 0.5; margin-top: 40px"
                      class="mr-2 main-color dateIcon"
                      >event</v-icon
                    >
                  </v-col>
                  <v-row v-if="paymentMethod == 'Bank Transfer'">
                    <v-col cols="12" md="6" style="padding-top: 0">
                      <p class="key">{{ $t("Transfer Number") }}</p>
                      <v-text-field
                        autocomplete="off"
                        v-model="paymentObject.transfer_number"
                        solo
                        dense
                        :rules="[validationRules.required]"
                        :disabled="submitted"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="5" style="padding-top: 0">
                      <p class="key">{{ $t("Transfer Date") }}</p>
                      <v-text-field
                        id="transfer_date"
                        autocomplete="off"
                        v-model="paymentObject.transfer_date"
                        solo
                        dense
                        :rules="[
                          checkTransferDate(paymentObject.transfer_date),
                        ]"
                        :disabled="submitted"
                        @keydown.prevent
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="1">
                      <v-icon
                        medium
                        style="float: right; opacity: 0.5; margin-top: 28px"
                        class="mr-2 main-color dateIcon"
                        >event</v-icon
                      >
                    </v-col>
                  </v-row>
                  <v-row v-if="paymentMethod == 'Check'">
                    <v-col cols="12" md="6" style="padding-top: 0">
                      <p class="key">{{ $t("Check Number") }}</p>
                      <v-text-field
                        autocomplete="off"
                        v-model="paymentObject.check_number"
                        solo
                        dense
                        :rules="[validationRules.required]"
                        :disabled="submitted"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="5" style="padding-top: 0">
                      <p class="key">{{ $t("Check Date") }}</p>
                      <v-text-field
                        id="check_date"
                        autocomplete="off"
                        v-model="paymentObject.check_date"
                        solo
                        dense
                        :rules="[validateCheckDate(paymentObject.check_date)]"
                        :disabled="submitted"
                        @keydown.prevent
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" md="1">
                      <v-icon
                        medium
                        style="float: right; opacity: 0.5; margin-top: 28px"
                        class="mr-2 main-color dateIcon"
                        >event</v-icon
                      >
                    </v-col>
                  </v-row>
                </v-row>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <div class="selectInput">
                <label for="select payment" class="main-color">{{
                  $t("Cash Point")
                }}</label>
                <v-select
                  solo
                  :items="cash_points"
                  v-model="paymentObject.cash_point_id"
                  item-text="name"
                  item-value="id"
                  :rules="[validationRules.required]"
                >
                  <!-- :disabled="submitted" -->
                </v-select>
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="textAreaInput">
                <label for="textarea">{{ $t("Notes") }}</label>
                <v-textarea
                  rows="2"
                  row-height="5"
                  :disabled="submitted"
                  solo
                ></v-textarea>
              </div>
            </v-col>
          </v-row>

          <hr class="second" />

          <v-row>
            <v-col cols="12" md="12">
              <createPaymentItemsComponent
                :items="items"
                :code="code"
                v-on:submit="submit"
              ></createPaymentItemsComponent>
            </v-col>
          </v-row>
        </v-form>
        <yesNoDialogComponent
          :message="confirmationMessage"
          v-on:confirmed="createPayment"
          ref="confirmationDialog"
        ></yesNoDialogComponent>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
// import ACL from "../../acl";
// import snackComponent from "@/components/snackComponent";
import createPaymentItemsComponent from "../../components/AccountStatment/createPaymentItemsComponent.vue";
import yesNoDialogComponent from "../../components/YesNoDialogComponent.vue";
import { validationMixin } from "../../mixins/validationMixin";
export default {
  name: "AccountStatementCreatePayment",
  components: { createPaymentItemsComponent, yesNoDialogComponent },
  mixins: [validationMixin],
  data() {
    return {
      submitted: false,
      types: [],
      dialogReset: false,
      snackbar: "",
      snackColor: "",
      snackMsg: "",
      confirmationMessage: "Are you sure you want to proceed?",
      validation_errors: {},
      valid: true,
      loading: true,
      formTitle: "",
      partner: {
        name: "name",
        code: "code",
        type: "student",
        grade: "KG 1",
      },
      selected: [],

      title: this.$i18n.t("Account Statement Details"),

      items: [],
      payload: [],
      item_ids: "",
      year_id: "",
      code: "",
      paymentMethod: "Cash",
      paymentMethods: [],
      cash_points: [],
      paymentObject: {
        cash_points: [],
        cash_point_id: "",
        notes: null,
        transaction_id: null,
        transaction_date: null,
      },
    };
  },
  watch: {},
  methods: {
    closeSnack() {
      this.snackbar = false;
    },
    getAllData() {
      axios
        .get(
          this.getApiUrl +
            "/account-statement/create-payment/" +
            this.code +
            "?item_ids=" +
            this.item_ids +
            "&year_id=" +
            this.year_id,
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          // this.setPaginationParameters(response);
          // this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.items;
          this.partner = response.data.data.partner;
          this.paymentObject = response.data.data.transaction;
          this.loading = false;
        });
    },

    selectPaymentMethod(method) {
      // if (!this.submitted) {
      // this.$refs.form.resetValidation();
      this.paymentMethod = method;
      var _this = this;
      // this.paymentObject.cash_points = [];
      if (this.paymentMethod == "Bank Transfer") {
        // this.paymentObject.voucher_number = null;
        this.paymentObject.check_number = null;
        this.paymentObject.check_date = null;
        $(document).ready(function () {
          $("#transfer_date").calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            onSelect: function (date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.paymentObject.transfer_date = dateH;
              } else {
                _this.paymentObject.transfer_date = "";
              }
            },
          });
        });
      } else if (this.paymentMethod == "Check") {
        // this.paymentObject.voucher_number = null;
        this.paymentObject.transfer_number = null;
        this.paymentObject.transfer_date = null;
        $(document).ready(function () {
          $("#check_date").calendarsPicker({
            dateFormat: "dd/mm/yyyy",
            onSelect: function (date) {
              if (date[0]) {
                var dateH =
                  date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
                _this.paymentObject.check_date = dateH;
              } else {
                _this.paymentObject.check_date = "";
              }
            },
          });
        });
      } else if (this.paymentMethod == "Cash") {
        this.paymentObject.check_number = null;
        this.paymentObject.check_date = null;
        this.paymentObject.transfer_number = null;
        this.paymentObject.transfer_date = null;
      }
      this.cash_points = this.paymentMethods[method];
    },
    checkTransferDate(value) {
      if (this.paymentMethod == "Bank Transfer") {
        return value ? true : "Transfer number is required";
      } else {
        return true;
      }
    },
    validateCheckDate(value) {
      if (this.paymentMethod == "Check") {
        return value != "" ? true : "Check Date is required";
      } else {
        return true;
      }
    },

    submit(items, shouldSubmit = false) {
      if (this.valid && shouldSubmit) {
        this.payload = items;
        // show confirm dialog
        this.$refs.confirmationDialog.show();
        // axios
        //   .post(
        //     this.getApiUrl +
        //       "/account-statement/create-payment-transaction/" +
        //       this.code,
        //     {
        //       items: items,
        //       transaction: this.paymentObject,
        //     },
        //     {
        //       headers: {
        //         Authorization: "Bearer " + localStorage.token,
        //       },
        //     }
        //   )
        //   .then((response) => {
        //     if (response.data.status.error == false) {
        //       this.$router.push("/AccountStatements/" + this.code);
        //     } else {
        //       if (
        //         Object.keys(response.data.status.validation_errors).length > 0
        //       ) {
        //         this.validation_errors.items =
        //           response.data.status.validation_errors.items;
        //       }
        //       this.snack = true;
        //       this.snackColor = "error";
        //       this.snackText = response.data.status.message;
        //     }
        //     this.loading = false;
        //     // this.$emit("getData");
        //   });
      } else {
        this.$refs.parentForm.validate();
      }
    },

    createPayment() {
      axios
        .post(
          this.getApiUrl +
            "/account-statement/create-payment-transaction/" +
            this.code,
          {
            items: this.payload,
            transaction: this.paymentObject,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          if (response.data.status.error == false) {
            this.$router.push("/AccountStatements/" + this.code);
          } else {
            if (
              Object.keys(response.data.status.validation_errors).length > 0
            ) {
              this.validation_errors.items =
                response.data.status.validation_errors.items;
            }
            this.snack = true;
            this.snackColor = "error";
            this.snackText = response.data.status.message;
          }
          this.loading = false;
          this.$refs.confirmationDialog.close();
          // this.$emit("getData");
        });
    },

    getPaymentMethods() {
      axios
        .get(
          this.getApiUrl + "/payments/getCashPoints",

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.paymentMethods = response.data.data;
          this.selectPaymentMethod("Cash");
        });
    },
  },
  mounted() {
    this.code = this.$router.currentRoute.params.code;
    this.item_ids = this.$router.currentRoute.query.item_ids;
    this.year_id = this.$router.currentRoute.query.year_id;
    this.getAllData();
    this.getPaymentMethods();
    var _this = this;
    $(document).ready(function () {
      $("#transaction_date").calendarsPicker({
        dateFormat: "dd/mm/yyyy",
        onSelect: function (date) {
          if (date[0]) {
            var dateH =
              date[0]._day + "/" + date[0]._month + "/" + date[0]._year;
            _this.paymentObject.transaction_date = dateH;
          } else {
            _this.paymentObject.transaction_date = "";
          }
        },
      });
    });
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";

.info-list {
  .value {
    font-size: 13px;
  }
}

.paymentBtn {
  padding-top: 6px !important;
}

hr.basic {
  margin: 0;
}

hr.second {
  border: 2px solid $main-color;
  margin: 10px auto;
  background-color: $main-color;
}

.v-data-table th {
  height: 25px;
}

.box {
  box-shadow: $box-shadow;
  width: 90%;
  padding: 10px 10px;
  margin-bottom: 1rem;
  height: 222px;

  .key {
    margin-bottom: 8px;
  }

  .value {
    color: $main-gray;
    margin-bottom: 8px;
  }
}

.payment-methods {
  padding-left: 0;

  li {
    display: inline-block;
    width: 25%;

    .icon {
      font-size: 2rem;
      color: $main-color;
      margin-bottom: 8px;
      padding: 5px 20px 5px;
      margin: 0 5px;
      border-radius: 5px;
    }

    .text {
      color: $main-color;
    }
  }
}

.active {
  background-color: $main-backgroundColor;
}

.selectable {
  cursor: pointer;
}

.disabled {
  cursor: not-allowed;

  .icon {
    color: $main-gray !important;
  }

  .text {
    color: $main-gray !important;
  }
}

.selectInput {
  // width: 90%;

  // // padding-left: 12px;
  .v-input {
    max-width: 90%;
  }
}

.textAreaInput {
  // padding-left: 12px;
  width: 90%;

  label {
    color: $main-color;
  }
}

.calendar-icon {
  padding-right: 5px;
}

.due-date {
  color: #ff8972;
}

.second-title {
  color: $main-color;
}

@media (max-width: 600px) {
  .second-title {
    margin-bottom: 0;
  }

  .box {
    height: auto;
    width: 100%;

    .payment-methods {
      li {
        .icon {
          padding: 5px 10px 5px;
        }
      }
    }
  }

  .info-list .value {
    margin-bottom: 0;
  }

  .selectInput {
    width: 100%;

    .v-input {
      max-width: 100%;
    }
  }

  .textAreaInput {
    width: 100%;
  }
}

.error--text {
  font-size: 1.1rem;
}
.headerTable-div {
  text-align: left !important;
}
</style>
