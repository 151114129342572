<template>
  <div>
    <v-form v-model="valid" ref="form">
      <v-data-table
        :loading="loading"
        loading-text="Loading... Please wait"
        :headers="headers"
        :items="items"
        item-key="id"
        :show-select="false"
        class="elevation-1 level1 templates"
        text="left"
        hide-default-footer
      >
        <template v-slot:item.amount_to_pay="{ item, index }">
          <v-text-field
            style="max-width: 200px; margin: 0 auto"
            v-model="item.amount_to_pay"
            solo
            class="amountToPay"
            :rules="[validateAmountToPay(item)]"
          ></v-text-field>
          <div v-if="Object.keys(validation_errors).length > 0">
            <p class="error--text" v-if="validation_errors.items[index]">
              {{ validation_errors.items[index][0] }}
            </p>
          </div>

          <!-- @keyup="calculateValues(item)" -->
        </template>
        <template v-slot:item.remaining="{ item }">
          {{ remaining(item) }}
        </template>
        <template v-slot:item.tax="{ item }"> {{ item.tax }} % </template>
        <template v-slot:item.tax_value="{ item }">
          {{ tax_value(item) }}
        </template>
        <template v-slot:item.net_after_tax="{ item }">
          {{ net_after_tax(item) }}
        </template>
      </v-data-table>
    </v-form>
    <hr class="second" />
    <v-row class="headerTable-div">
      <v-col md="4" sm="12">
        <span
          :class="
            currentAppLocale == 'ar' ? 'ml-2 custom-bold' : 'mr-2 custom-bold'
          "
          >{{ $t("Total Amount before Tax:") }}</span
        >
        <span class="bold">{{ total_amount_before_tax }}</span>
      </v-col>
      <v-col md="4" sm="12">
        <span
          :class="
            currentAppLocale == 'ar' ? 'ml-2 custom-bold' : 'mr-2 custom-bold'
          "
          >{{ $t("Total Tax Value:") }}</span
        >
        <span class="bold">{{ total_tax_val }}</span>
      </v-col>
      <v-col md="4" sm="12">
        <span
          :class="
            currentAppLocale == 'ar' ? 'ml-2 custom-bold' : 'mr-2 custom-bold'
          "
          >{{ $t("Total Net after Tax:") }}</span
        >
        <span class="bold">{{ total_net_after_tax }}</span>
      </v-col>
    </v-row>
    <!-- <hr class="second" /> -->
    <v-row class="mt-5">
      <v-col cols="12" md="8"></v-col>
      <v-col cols="12" md="4" style="text-align: right">
        <v-btn class="confirm main-color mr-2" @click="cancelPayment">
          {{ $t("Cancel") }}
        </v-btn>
        <v-btn
          class="modal-btn-save"
          @click="createInvoice()"
          :loading="loading"
        >
          {{ $t("Create Invoice") }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { validationMixin } from "../../mixins/validationMixin";
// import axios from "axios";
export default {
  name: "createPaymentItemsComponent",
  mixins: [validationMixin],
  props: ["items", "code"],
  data() {
    return {
      valid: true,
      loading: false,
      headers: [
        {
          text: this.$i18n.t("Item"),
          value: "item",
          sortable: false,
        },
        {
          text: this.$i18n.t("After Discount"),
          value: "after_discount",
          sortable: false,
        },
        {
          text: this.$i18n.t("Paid Amount"),
          value: "paid_amount",
          sortable: false,
        },
        {
          text: this.$i18n.t("Amount to Pay"),
          value: "amount_to_pay",
          sortable: false,
          width: "20%",
          //   TODO input width
        },
        {
          text: this.$i18n.t("Remaining"),
          value: "remaining",
          sortable: false,
        },
        {
          text: this.$i18n.t("Tax"),
          value: "tax",
          sortable: false,
        },
        {
          text: this.$i18n.t("Tax Value"),
          value: "tax_value",
          sortable: false,
        },
        {
          text: this.$i18n.t("Net after Tax"),
          value: "net_after_tax",
          sortable: false,
        },
      ],
      validation_errors: {},
      // total_tax_val: 0,
      // total_net_after_tax: 0,
    };
  },
  methods: {
    cancelPayment() {
      this.$router.push("/AccountStatements/" + this.code);
    },
    validateAmountToPay(row) {
      console.log(row.amount_to_pay);
      if (parseInt(row.amount_to_pay) <= 0) {
        return this.$i18n.t("Amount must be greater than 0");
      } else if (row.amount_to_pay != "") {
        return (
          parseInt(row.amount_to_pay) <=
            parseInt(row.after_discount) - parseInt(row.paid_amount) ||
          this.$i18n.t("Amount can't exceed ") +
            (parseInt(row.after_discount) - parseInt(row.paid_amount))
        );
      }
      return this.$i18n.t("This field is required");
    },
    calculateTotals() {},
    createInvoice() {
      if (!this.valid) {
        this.$refs.form.validate();
        this.$emit("submit", this.items, false);
      } else {
        // this.loading = true;
        // axios
        //   .post(
        //     this.getApiUrl +
        //       "/account-statement/create-payment-transaction/" +
        //       this.code,
        //     {
        //       items: this.items,
        //     },
        //     {
        //       headers: {
        //         Authorization: "Bearer " + localStorage.token,
        //       },
        //     }
        //   )
        //   .then((response) => {
        //     if (response.data.status.error == false) {
        //       this.$router.push("/AccountStatements/" + this.code);
        //     } else {
        //       if (
        //         Object.keys(response.data.status.validation_errors).length > 0
        //       ) {
        //         this.validation_errors.items =
        //           response.data.status.validation_errors.items;
        //       }
        //       this.snack = true;
        //       this.snackColor = "error";
        //       this.snackText = response.data.status.message;
        //     }
        //     this.loading = false;
        //   });
        this.$emit("submit", this.items, true);
      }
    },
    roundToTwo(num) {
      return (+(Math.round(num + "e+2") + "e-2")).toFixed(2);
    },
  },

  computed: {
    remaining() {
      return (row) => {
        if (row.amount_to_pay != "")
          return this.roundToTwo(
            parseFloat(row.after_discount) -
              parseFloat(row.paid_amount) -
              parseFloat(row.amount_to_pay)
          );
        else return this.roundToTwo(parseFloat(row.remaining));
      };
    },
    tax_value() {
      const _this = this;
      return (row) => {
        if (_this.valid)
          return this.roundToTwo(
            (parseFloat(row.tax) * parseFloat(row.amount_to_pay)) / 100
          );
        else return 0;
      };
    },
    net_after_tax() {
      const _this = this;
      return (row) => {
        if (_this.valid)
          return this.roundToTwo(
            (parseFloat(row.tax) * parseFloat(row.amount_to_pay)) / 100 +
              parseFloat(row.amount_to_pay)
          );
        else return 0;
      };
    },
    total_amount_before_tax() {
      if (this.valid) {
        let returnVal = 0;
        this.items.forEach((item) => {
          returnVal += parseFloat(item.amount_to_pay);
        });
        return this.roundToTwo(returnVal);
      }
      return 0;
    },
    total_tax_val() {
      let val = 0;
      if (this.valid) {
        this.items.forEach((item) => {
          val += parseFloat(this.tax_value(item));
        });
      }
      return this.roundToTwo(val);
    },
    total_net_after_tax() {
      let val = 0;
      if (this.valid) {
        this.items.forEach((item) => {
          val += parseFloat(this.net_after_tax(item));
        });
      }
      return this.roundToTwo(val);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
hr.second {
  border: 2px solid $main-color;
  margin: 10px auto;
  background-color: $main-color;
}
</style>
