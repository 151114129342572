<template>
  <v-dialog
    v-model="dialogConfirm"
    persistent
    max-width="500"
    class="dropdown-item dialog_confirm"
  >
    <v-card>
      <v-card-title class="headline">{{
        $t("Confirmation Message")
      }}</v-card-title>

      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="modal-btn-cancel" @click="close()">
          {{ $t("No") }}
        </v-btn>
        <v-btn
          class="modal-btn-save"
          @click="confirm()"
          :loading="loading"
          style="margin-right: 0 !important"
        >
          {{ $t("Yes") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "YesNoDialogComponent",
  props: ["message"],
  data() {
    return {
      dialogConfirm: false,
      loading: false,
    };
  },
  methods: {
    show() {
      this.dialogConfirm = true;
    },

    close() {
      this.dialogConfirm = false;
      this.loading = false;
    },

    confirm() {
      // emit to parent
      setTimeout(() => {
        this.$emit("confirmed");
      }, 1000);
      this.loading = true;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../styles/_variables.scss";
@import "../styles/_modals.scss";
</style>
